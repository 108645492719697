@import 'styles/theme';

$drawerWidth: 200px;

.drawer {
    width: $drawerWidth;
    flex-shrink: 0;
    border: none;
}

.drawerPaper {
    width: $drawerWidth;
    border: none;
}

// necessary for content to be below app bar
.logo {
    padding: 20px 20px 20px 20px;
}

.listItem:hover {
    background: $primaryFaded;
}

.listItemActive {
    background-color: $primaryLight !important;
}

.listItemActive:hover {
    background: $primaryLight !important;
}

.listItemTextActive {
    color: $primaryMain;
    font-weight: 700 !important;
}

.listItemText {
    font-weight: 300 !important;
}

.listItemIconActive {
    color: $primaryMain !important;
}
