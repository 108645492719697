@import 'styles/theme';

.root {
    display: flex;
    min-height: 100%;
}

.content {
    flex-grow: 1;
    background: $backgroundDefault;
    min-height: 100vh;
    height: 100%;
}
